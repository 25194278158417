import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { FuseConfirmationModule } from '@src/@fuse/services/confirmation';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        PipesModule,

        FuseConfirmationModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        PipesModule,

        FuseConfirmationModule
    ]
})
export class SharedModule
{
}
