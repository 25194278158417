export const environment = {
    production: true,
    
    apiBaseUrl: 'https://api.v2.gwcregaliahire.nz',

    orderFrontendBulkOrderBaseUrl: 'https://orders.gwcregaliahire.nz/bulk-order-form',
    orderFrontendBulkIndividualOrderBaseUrl: 'https://orders.gwcregaliahire.nz/bulk-individual-order-form',
    orderFrontendIndividualOrderBaseUrl: 'https://orders.gwcregaliahire.nz/individual-order-form',

    signInCredentials: {
        email: null,
        password: null
    }

};
