import { Injectable } from '@angular/core';
import { GwcApiConnService } from './gwc-api-conn.service';
import { Observable } from 'rxjs';
import { Institution } from '@src/app/types/gwc-api/institution.types';
import { Graduation } from '@src/app/types/gwc-api/graduation.types';
@Injectable({
    providedIn: 'root'
})
export class GwcApiInstitutionsService {


    constructor(
        private _connService: GwcApiConnService
    ) {

    }

    //------------------------------------------------------------------
    // @Basic CRUD Methods
    //------------------------------------------------------------------

    getInstitutions(params?): Observable<Institution[]> {
        return this._connService.request('GET', 'Institution', params) as Observable<Institution[]>
    }

    getInstitution(institution_id: string): Observable<Institution> {
        return this._connService.request('GET', ['Institution', institution_id]) as Observable<Institution>
    }

    createInstitution(data): Observable<any> {
        return this._connService.request('POST', ['Institution'], null, data) as Observable<any>
    }

    updateInstitution(institution_id: string, data): Observable<any> {
        return this._connService.request('PUT', ['Institution', institution_id], null, data) as Observable<any>
    }


    //------------------------------------------------------------------
    // @Advances Methods
    //------------------------------------------------------------------

    getGraduations(institution_id: string, queryParams?: { [key: string]: string } ): Observable<Graduation[]> {
        return this._connService.request('GET', ['Institution', institution_id, 'graduations'], queryParams) as Observable<Graduation[]>
    }

    getGraduationsCeremonies(institution_id: string): Observable<Graduation[]> {
        return this._connService.request('GET', ['Institution', institution_id, 'graduations', 'ceremonies']) as Observable<Graduation[]>
    }

    getQualifications(institution_id: string, params?): Observable<any> {
        return this._connService.request('GET', ['Institution', institution_id, 'qualifications'], params) as Observable<any>
    }

    getGraduationDashboard(graduation_id: string): Observable<any> {
        return this._connService.request('GET', ['v2', 'graduations', graduation_id, 'dashboard']) as Observable<any>
    }

    generatePickUpEmails(graduation_id: string): Observable<any> {
        return this._connService.request('POST', ['Graduation', graduation_id, 'IndividualOrders', 'PickupNotification', 'generate']) as Observable<any>
    }

}