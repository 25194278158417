import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { F3DatePipe } from './f3-date.pipe';

@NgModule({
  declarations:[
    F3DatePipe
  ], 
  imports:[
    CommonModule
  ],
  exports:[
    F3DatePipe
  ] 
})

export class PipesModule{}