import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { mergeMap } from 'rxjs/operators';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from './nav-data/data';
import { GwcApiInstitutionsService } from '@src/app/services/gwc-api/institutions.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _gwcApiInstitutionsService: GwcApiInstitutionsService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {


        return this._gwcApiInstitutionsService.getInstitutions( { type: 'UNIVERSITY,POLYTECH,OTHER_TERTIARY' } ).pipe(
            mergeMap(institutions => {

                // Fill compact navigation children using the default navigation
                this._compactNavigation.forEach((compactNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if(defaultNavItem.id == 'institution') {
                            compactNavItem.children = this._generateInstitutionLinks(institutions)
                        } else if ( defaultNavItem.id === compactNavItem.id ) {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigation.forEach((futuristicNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if(defaultNavItem.id == 'institution') {
                            futuristicNavItem.children = this._generateInstitutionLinks(institutions)
                        } else if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigation.forEach((horizontalNavItem) => {
                    this._defaultNavigation.forEach((defaultNavItem) => {
                        if(defaultNavItem.id == 'institution') {
                            horizontalNavItem.children = this._generateInstitutionLinks(institutions)
                        } else if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                this._defaultNavigation.forEach((defaultNavItem) => {
                    if(defaultNavItem.id == 'institution') {
                        defaultNavItem.children = this._generateInstitutionLinks(institutions)
                    }
                })

                // Return the response
                return of(
                    {
                        compact   : cloneDeep(this._compactNavigation),
                        default   : cloneDeep(this._defaultNavigation),
                        futuristic: cloneDeep(this._futuristicNavigation),
                        horizontal: cloneDeep(this._horizontalNavigation)
                    }
                ).pipe(
                    tap((navigation) => {
                        this._navigation.next(navigation)
                    })
                ) as Observable<Navigation>


            })
        )
    }


    private _generateInstitutionLinks(institutions) {
        return institutions.map(inst => {
            return {
                id: `institution.${inst.institution_id}`,
                title: inst.institution,
                type: 'basic',
                icon: 'heroicons_outline:library',
                link: `/institution/${inst.institution_id}`
            }
        })
    }


}
